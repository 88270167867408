.parental__control_block_services {
    display: flex;
    margin-top: 10px;
    min-height: calc(100vh - 151px);
    border: 1px #E6E6E6FF solid;
    border-radius: 15px 15px 0 0;
    background-color: #F3F4FA;
    color: black;
    flex-direction: column;
    align-items: center;
}

.parental__control_block_bottom {
    position: absolute;
    top: 141px;
    transition: 0.3s;
    width: 100%;
    display: flex;
    visibility: hidden;
    margin-top: 10px;
    min-height: calc(100vh - 151px);
    border: 1px #E6E6E6FF solid;
    border-radius: 15px 15px 0 0;
    background-color: #F3F4FA;
    color: black;
    flex-direction: column;
    align-items: center;
    opacity: 0;
}

.parental__control_block_bottom.active {
    z-index: 1;
    visibility: visible;
    opacity: 1;
}

.parental__control_block_bottom.active .arrowLeft {
    filter: brightness(0%) contrast(100%);
    margin: 10px;
    height: 24px;
    width: 24px;
}

.parental__control_block_bottom .first_row {
    display: flex;
    align-items: center;
    position: absolute;
    left: 5px;
}

.parental__control_block_bottom .second_row {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
}

.second_row .date_block {
    display: flex;
    border-radius: 10px;
    border: lightgrey 1px solid;
    height: 60px;
    margin-bottom: 15px;
    width: 92vw;
}

.parental__control .arrowLeft {
    display: flex;
    margin: 50px 0 5px 15px;
    height: 24px;
    width: 24px;
}

.parental__control h2{
    color: white;
    font-size: 26px;
    font-weight: 600;
    height: 62px;
    margin: 0 0 0 30px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.toggle {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    width: 92%;
}

.toggle-switch {
    display: inline-block;
    background: #e3e3e3;
    border-radius: 16px;
    width: 40px;
    height: 16px;
    position: relative;
    vertical-align: middle;
    transition: background 0.5s;
    margin-right: 10px;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: #ccc;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -4px;
    left: -4px;
    transition: left 0.5s;
}
/*.toggle:hover .toggle-switch:before {*/
/*    background: linear-gradient(to bottom, #fff 0%, #2898ff 100%);*/
/*}*/

.toggle-checkbox:checked + .toggle-switch {
    background: rgba(40, 152, 255, 0.3);
}
.toggle-checkbox:checked + .toggle-switch:before {
    background: #2898ff;
    left: 20px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 15px;
    position: relative;
    font-size: 20px;
}

.toggle-label p {
    margin: 2px;
    font-size: 14px;
}

.toggle-label img {
    margin-left: 10px;
}

.parental__control button {
    margin: 10px auto 7px auto;
    display: block;
    padding: 8px 0;
    font-size: 14px;
    width: 240px;
    border-radius: 8px;
    border: #298BFF 2px solid;
    background-color: #298BFF;
    font-weight: 700;
    color: white;
}

.parental__control #button_delete {
    margin: 10px auto 7px auto;
    display: block;
    padding: 8px 0;
    font-size: 14px;
    width: 240px;
    border-radius: 8px;
    border: #cc0000 2px solid;
    background-color: #cc0000;
    font-weight: 700;
    color: white;
}

.parental__control_block_services p {
    margin-top: 20px;
    width: 90%;
    font-size: 12px;
}

.parental__control_modal{
    display: none;
}

.parental__control_modal.active{
    display: flex;
    flex-direction: column;
    margin-left: 4vw;
    background: white;
    height: 55vh;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 180px;
    width: 92vw;
}

.parental__control_modal select {
    height: 45px;
    width: 100px;
    font-size: 20px;
    margin: 20px;
    padding-left: 20px;
    border-radius: 10px;
    border: none;
}

.parental__control_modal span {
    margin: 15px 25px 15px 25px;
}

.parental__control_modal .button_day {
    margin: 5px 2px 7px 2px;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 11vw;
    height: 11vw;
    border-radius: 10vw;
    border: none;
    background-color: lightgrey;
    font-weight: 700;
    color: black;
}

.parental__control_modal .button_day.active {
    color: white;
    background-color: #298BFF;
}