.personal__account {
}

.personal__account_block_info {
    display: flex;
    margin: 70px auto 0 auto;
    width: 90%;
}

.banner {
    width: 90%;
    height: 90px;
    margin-top: 15px;
    border: 1px solid black;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.personal__account_block_info h1 {
    display: flex;
    margin: 5px 0 0 20px;
    width: 90%;
    color: white;
}

.personal__account_block_info img {
    position: absolute;
    right: 0;
    margin: -10px 20px 0 10px;
}

.personal__account_block_status {
    display: flex;
    /*flex-direction: row;*/
    width: 90%;
    /*justify-content: center;*/
    /*margin: 10px 15px 0 15px;*/
    /*margin: 5px 0 0 20px;*/
    margin: 10px auto 0 auto;
    color: white;
}

.personal__account_block_status div{
    margin: 0 0 0 20px;
    /*margin-left: 20px;*/
}

.personal__account_block_status img {
    margin: 0 0 0 10px;
}

.personal__account_block_scroll {
    margin: 0;
    display: flex;
    width: 100vw;
    flex-direction: row;
    overflow: scroll;
    justify-content: flex-start;
}

.personal__account_tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 24vw;
    font-size: 14px;
    margin: 20px 0 10px 2vh;
    min-height: 80px;
    text-align: center;
    border: #E6E6E6FF 2px solid;
    border-radius: 10px;
    background-color: #E6E6E6FF;
}

.personal__account_block_scroll::-webkit-scrollbar {
    display: none;
}

.personal__account_block_services {
    display: flex;
    margin-top: 18px;
    /*margin-bottom: 10px;*/
    padding-bottom: 10px;
    min-height: calc(100vh - 151px);
    border: 1px #E6E6E6FF solid;
    border-radius: 15px 15px 0 0;
    background-color: #F3F4FA;
    color: black;
    flex-direction: column;
    align-items: center;
}

.personal__account_dropdown {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    background-color: white;
    margin: 20px 0 15px;
    border-radius: 15px;
    height: 92px;
    position: relative;
    z-index: 3;
}

.personal__account_dropdown * {
    box-sizing: border-box;
}

.select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px white solid;
    border-radius: 15px;
    cursor: pointer;
    width: 90%;
}

.selected {
    margin-top: -25px;
}

.caret {
    width: 20px;
    height: 10px;
    transition: 0.3s;
    background: top center / contain no-repeat url("../../assets/vectors/arrowDown.svg");
}

.caret.rotate {
    transform: rotate(180deg);
}

.menu {
    list-style: none;
    padding: 0;
    background: white;
    border-radius: 0 0 15px 15px;
    position: absolute;
    top: 60px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;
}

.menu li {
    margin-bottom: 15px;
    margin-top: 10px;
}

.active {
    display: none;
}

.menu.open {
    visibility: visible;
    opacity: 1;
    z-index: 3;
}

.menu_successfully {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding: 0;
    background: white;
    /*border-radius: 15px 15px 0 0;*/
    border-radius: 15px;
    position: absolute;
    top: 20%;
    height: 80%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;
}

.menu_successfully button {
    margin: 0 15px 7px 15px;
    padding: 8px 0;
    width: 115px;
    border-radius: 8px;
    border: #298BFF 2px solid;
    background-color: white;
    font-weight: 700;
    color: #298BFF;
}

.menu_successfully.open {
    visibility: visible;
    opacity: 1;
    z-index: 3;
}

.menu_successfully h2 {
    display: flex;
    justify-content: center;
}

.menu_successfully img {
    display: flex;
    justify-content: center;
    margin: auto;
}

.background {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}

.background.active {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
}

.personal__account_dropdown h4 {
    margin: 15px 0 0 20px;
    font-size: 17px;
}

.personal__account_dropdown p {
    margin: 8px 0 0 20px;
    font-size: 12px;
}

.personal__account_services {
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
    background-color: white;
    width: 90%;
    margin-bottom: 15px;
}

.personal__account_services .left_block{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-right: 12px;
}

.personal__account_services .right_block{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
    margin-top: 10px;
    max-width: 120px;
}

.right_block button {
    margin: 0 15px 7px 15px;
    padding: 8px 0;
    width: 115px;
    border-radius: 8px;
    border: #298BFF 2px solid;
    background-color: white;
    font-weight: 700;
    color: #298BFF;
}

.right_block button.active{
    margin: 0 15px 7px 15px;
    display: block;
    padding: 8px 0;
    width: 115px;
    border-radius: 8px;
    border: #298BFF 2px solid;
    background-color: #298BFF;
    font-weight: 700;
    color: white;
}

.left_block .first_row {
    display: flex;
    flex-direction: row;
    margin: 10px 5px 0 20px;
    height: 40px;
}

.left_block .first_row h4 {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.left_block .first_row img {
    width: 24px;
    height: 26px;
    margin-top: 5px;
}

.left_block .second_row {
    display: flex;
    flex-direction: row;
    margin: 0 0 5px 20px;
}

.left_block .second_row h4 {
    margin: 0;
}

.left_block .second_row h3 {
    height: 30px;
    margin: 5px 0 0 0;
}

.loading__spinner{
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
}

.loading__spinner img {
    display: flex;
    height: 90%;
    max-height: 80px;
    max-width: 80px;
    margin: auto;
}