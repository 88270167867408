body {
  font-family: "Roboto", sans-serif;
  background: top center / contain no-repeat url("./assets/vectors/backgroundINSIT.svg"),
  #1D5ECD;
  background-size: 100% 186px;
  margin: 0;
}

html {
  display: table;
  margin: 0;
  width: 100%;
  height: 100%;
}