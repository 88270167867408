.intercom_events {
    display: flex;
    flex-direction: column;
}

.intercom_events .arrowLeft {
    display: flex;
    margin: 50px 0 10px 15px;
    height: 24px;
    width: 24px;
}

.intercom_events__info {
    display: flex;
    margin-top: 18px;
    padding-bottom: 10px;
    padding-top: 15px;
    min-height: calc(100vh - 102px);
    border: 1px #E6E6E6FF solid;
    border-radius: 15px 15px 0 0;
    background-color: #F3F4FA;
    color: black;
    flex-direction: column;
    align-items: center;
}

.intercom_events__data {
    display: flex;
    font-size: 15px;
    padding: 10px;
    justify-content: space-between;
    border-radius: 15px;
    background-color: white;
    width: 90%;
    margin-bottom: 15px;
}
