.payment {
    display: flex;
    flex-direction: column;
}

.payment .arrowLeft {
    display: flex;
    margin: 50px 0 10px 15px;
    height: 24px;
    width: 24px;
}

.payment__info_block {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    width: 90%;
    background: white;
    border-radius: 25px;
    color: grey;
}

.payment__info_block h2 {
    display: flex;
    padding-left: 30px;
    margin: 5px 0 0 0;
    font-size: 40px;
    color: #298BFF;
}

.payment__info_block h3 {
    display: flex;
    padding-left: 30px;
    margin: 15px 0 0 0;
    font-size: 28px;
    color: black;
}

.payment__info_block h4 {
    display: flex;
    padding-left: 20px;
    margin: 20px 0 0 0;
}

.payment__info_block h5 {
    display: flex;
    margin-top: 12px;
    /*color: white;*/
    color: grey;
    font-size: 16px;
}

.payment__info_block p {
    display: flex;
    padding-left: 20px;
    font-size: 14px;
    color: lightgray;
}

.recommended {
    display: flex;
    flex-direction: row;
}

.recommended p {
    color: grey;
}

.payment__block {
    display: flex;
    margin-top: 15px;
    border: 1px #E6E6E6FF solid;
    border-radius: 20px;
    color: black;
    flex-direction: column;
    align-items: center;
    background: white;
}

.payment__block h4 {
    display: flex;
    margin: 10px;
}

.input_form {
    display: flex;
    width: 80%;
    height: 50px;
    font-size: 35px;
    border: 1px #E6E6E6FF solid;
    border-radius: 10px;
    background-color: #E6E6E6FF;
    margin-bottom: 15px;
}

input{
    text-align:center;
}
.payment__block button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 15px 15px;
    padding: 8px 0;
    width: 80%;
    height: 50px;
    border-radius: 10px;
    border: #298BFF 2px solid;
    background-color: white;
    font-weight: 700;
    color: #298BFF;
    font-size: 14px;
}

/*.menu_successfully {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    list-style: none;*/
/*    padding: 0;*/
/*    background: white;*/
/*    border-radius: 15px 15px 0 0;*/
/*    position: absolute;*/
/*    top: 40%;*/
/*    height: 60%;*/
/*    width: 100%;*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*    transition: 0.3s;*/
/*    z-index: 3;*/
/*}*/

/*.menu_successfully.open {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*    z-index: 3;*/
/*}*/

/*.menu_successfully h2 {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.menu_successfully img {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin: auto;*/
/*}*/

.background {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
}

.background.active {
    opacity: 1;
    pointer-events: all;
}